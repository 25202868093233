<template>
  <div class="home-container">
    <img alt="logo" class="logo" src="../assets/quest10n.png">
    <home-view />
  </div>
</template>

<script>
import HomeView from "@/components/HomeView.vue";

export default {
  components: { HomeView }
}
</script>

<style scoped lang="scss">
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 60px;
}
.logo {
  max-width: 370px;
  width: 60%
}
</style>
