<template>
  <div class="question-view">
    <div class="question-view__timer">
      <router-link v-if="gameOver" to="/" class="home-button" @click="handleGameOver">
        <img src="../assets/house.svg" alt="Home" />
      </router-link>
      {{ timer }}
      <div v-if="!gameOver" class="close-button" @click="handleGameOver">
        ×
      </div>
    </div>
    <div class="question-view__question" v-if="currentQuestion">
      <span>{{ currentQuestion.question }}</span>
    </div>
    <div class="question-view__answers" v-if="currentQuestion">
        <div v-for="(answer, i) in currentQuestion.answers"
             :key="i"
             :class="{'question-view__answers__item': true, 'selected': isSelectedAnswer(answer) }"
             @click="selectAnswer(answer)"
        >
          <div>
            {{ answer.answer }}
          </div>
          <div class="points">
            {{ answer.points }}
          </div>
        </div>
    </div>
    <div class="question-view__popup" v-if="showPopup">
      <div class="popup-content start-game" v-if="hasSelectedTeam">
        <span class="team-color" :style="`background-color: ${getSelectedTeam.color}`" />
        <span>{{ `Team: ${getSelectedTeam.name} ( ${getSelectedTeam.points} points )` }}</span>
        <span v-if="currentQuestion">{{ currentQuestion.question }}</span>
        <div class="buttons-continaer">
          <button class="button" @click="startGame">Ready</button>
          <button class="transparent" @click="changeQuestion"><img src="../assets/swap.svg" /></button>
        </div>
        <span class="notification">You will be charged 5 points for every new question request.</span>
        
      </div>
    </div>
    <div class="game-over-popup" v-if="gameOver">
      <div class="popup-content teams-table">
        <div class="team">
          <div>
            Total Points: {{ totalPoints }}
          </div>
          <button class="button submit" @click="nextGame">Next Team</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'QuestionView',
  data() {
    return {
      questions: [],
      selectedCategories: [],
      gameOver: false,
      timer: this.getMaxTimeout,
      showPopup: true,
      currentQuestion: null,
      interval: null,
      teamIndex: 0,
      endGame: false,
      selectedAnswers: [],
      totalPoints: 0,
      totalGames: 0
    }
  },
  computed: {
    ...mapGetters([
      'getQuestions',
      'getSelectedCategories',
      'hasSelectedCategories',
      'getCategories',
      'getTeams',
      'getSelectedTeam',
      'getUsedQuestions',
      'getTeamScore',
      'getMaxTimeout',
      'getQuestionsPerTeam',
      'getDifficulty'
    ]),
    hasSelectedTeam() {
      return this.getSelectedTeam;
    },
    isSelectedAnswer() {
      return answer => this.selectedAnswers.includes(answer.answer);
    }
  },
  methods: {
    init() {
      this.$store.commit('setSelectedTeam', this.getTeams[this.teamIndex]);
      this.selectedCategories = this.hasSelectedCategories ? this.getSelectedCategories : this.getCategories;
      this.newQuestion();
    },
    newQuestion() {
      this.questions = this.getQuestions
          .filter(item => {
            const cat = this.hasSelectedCategories ? this.selectedCategories.includes(item.category) : true;
            const used = this.getUsedQuestions.includes(item.question);
            let difficulty = [1,2,3,4,5,6,7,8,9,10];
            if (this.getDifficulty === 'Easy') {
              difficulty = [1,2];
            } else if (this.getDifficulty === 'Medium') {
              difficulty = [3,4,5];
            } else if (this.getDifficulty === 'Hard') {
              difficulty = [6,7,8,9,10];
            }
            return cat && !used && difficulty.includes(item.difficulty);
          })
          .sort(() => Math.random() - Math.random()).slice(0, this.getTeams.length);
      this.currentQuestion = this.questions[this.teamIndex];
    },
    changeQuestion() {
      this.newQuestion();
      const score = +this.getTeamScore(this.getSelectedTeam.name) - 5;
      this.$store.dispatch('setTeamScore', {teamName: this.getSelectedTeam.name, score:score});
    },
    startGame() {
      const root = document.documentElement;
      clearInterval(this.interval);
      this.timer = this.getMaxTimeout;
      this.totalPoints = 0;
      this.selectedAnswers = [];
      root.style.setProperty('--timer-color', '#61c666');
      root.style.setProperty('--timer-width', "100%");
      this.interval = setInterval(() => {
        this.timer--;
        root.style.setProperty('--timer-width', ((this.timer * 100) / this.getMaxTimeout) + "%");
        if (this.timer <= 10) {
          root.style.setProperty('--timer-color', '#de1212');
        } else if (this.timer <= 25) {
          root.style.setProperty('--timer-color', '#e18952');
        }
        if (this.currentQuestion.answers.length === this.selectedAnswers.length) {
          this.handleGameOver()
        }
        if (this.timer < 0) {
          this.timer = 0;
          this.handleGameOver()
        }
      }, 1000);
      this.showPopup = false;
      this.$store.dispatch('setUsedQuestions', this.currentQuestion.question);
    },
    handleGameOver() {
      clearInterval(this.interval);
      this.gameOver = true;
      this.showPopup = false;
    },
    nextGame() {
      this.teamIndex++;
      if (this.teamIndex >= this.getTeams.length) {
        this.teamIndex = 0;
        this.totalGames++;
      }
      if (this.totalGames === this.getQuestionsPerTeam) {
        this.endGame = true;
        this.$router.push('/game-over');
        return;
      }
      this.init();
      this.gameOver = false;
      this.showPopup = true;
    },
    selectAnswer(answer) {
      if (this.selectedAnswers.includes(answer.answer)) {
        this.selectedAnswers = this.selectedAnswers.filter(item => item !== answer.answer);
        const score = +this.getTeamScore(this.getSelectedTeam.name) - +answer.points;
        this.totalPoints = this.totalPoints - answer.points;
        this.$store.dispatch('setTeamScore', {teamName: this.getSelectedTeam.name, score:score});
      } else {
        this.selectedAnswers.push(answer.answer);
        const score = +this.getTeamScore(this.getSelectedTeam.name) + +answer.points;
        this.totalPoints = this.totalPoints + answer.points;
        this.$store.dispatch('setTeamScore', {teamName: this.getSelectedTeam.name, score:score});
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    getQuestions() {
      this.init();
    }
  }
}
</script>
<style scoped lang="scss">
.question-view {
  margin-top: 50px;
  &__timer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    width: calc(100% - 10px);
    padding: 10px 0;
    font-size: 20px;
    background-image: linear-gradient(329deg, #d2d2d2 20%, #ffffff 100%);
    border-radius: 6px;
    margin: 5px;
    color: #3b3b3b;
    font-weight: bold;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: var(--timer-width);
      height: 5px;
      background-color: var(--timer-color);
      border-radius: 6px;
      transition: width 1s linear, background-color 1s linear;
      display: block;
    }
  }
  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: #000000a6;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .popup-content {
      width: 90%;
      height: 90%;
      background: #fbfbfb;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius);
      color: #494949;
      gap: 20px;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      overflow: hidden;
      padding: 0 20px;
      box-sizing: border-box;
      &.teams-table{
        padding: 30px 0;
      }
      .team-color {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        box-shadow: 3px 0px 3px #7c7c7c;
      }
      button.button {
        margin-top: 0!important;
        box-shadow: 0 3px 11px #535353;
      }
    }
    .teams {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      width: 80%;
      box-sizing: border-box;
      gap: 3px;
      color: white;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        border-radius: var(--border-radius);
      }
      button.button {
        width: 100%!important;
        margin-top: 40px!important;
        max-width: none;
      }
    }
  }
  &__question {
    margin: 70px 0 20px 0;
    font-size: 18px;
    font-weight: bold;
  }
  &__answers {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    &__item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background: #fbfbfb;
      border-radius: var(--border-radius);
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      color: var(--color-gray-darkest);
      box-sizing: border-box;
      &.selected {
        background: #bababa;
        color: #262626;
        &:after {
          content: "";
          position: absolute;
          left: 10px;
          font-size: 20px;
          background: #0000007a;
          height: 1px;
          width: 94%;
          width: calc(100% - 20px);
          display: block;
        }
        .points {
          background: #868690;
        }
      }
      .points {
        background: var(--color-primary);
        aspect-ratio: 1;
        height: 20px;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 100%;
        font-size: 12px;
      }
      div {
        text-align: left;
      }
    }
  }
  .game-over-popup {
    border-top: 1px solid white;
    margin-top: 15px;
    padding-top: 10px;
    font-weight: bold;
    .popup-content.teams-table {
      margin-bottom: 30px;
    }
  }
  .close-button {
    color: #606060;
    position: absolute;
    right: 3px;
    top: 2px;
    padding: 10px;
  }
  .home-button {
    position: absolute;
    left: 3px;
    top: 2px;
    padding: 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .buttons-continaer {
    position: relative;
    button.transparent {
      background: none;
      position: absolute;
      right: -90px;
      top: 3px;
      cursor: pointer;
      border: 1px solid black;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      padding: 6px 0 0 2px;
      img {
        width: 21px;
        height: 21px;
      }
    }
  }
  .notification {
    margin-top: 20px;
    font-size: 14px;
    color: #737373;
    font-style: italic;
    font-weight: 400;
  }
}
</style>
