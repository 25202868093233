import { createStore } from 'vuex'

export default createStore({
  state: {
    teams: [],
    questions: [],
    categories: [],
    selectedCategories: [],
    selectedTeam: null,
    usedQuestions: [],
    maxTimeout: 60,
    questionsPerTeam: 10,
    difficulty: 'All'
  },
  getters: {
    getTeams: state => state.teams,
    getQuestions: state => state.questions,
    getCategories: state => state.categories,
    getSelectedCategories: state => state.selectedCategories,
    hasSelectedCategories: state => state.selectedCategories.length > 0,
    getSelectedTeam: state => state.selectedTeam,
    getUsedQuestions: state => state.usedQuestions,
    getTeamScore: state => (teamName: any) => {
      const team: any = state.teams.find((t: any) => t.name === teamName)
      return team ? team.points : 0
    },
    getMaxTimeout: state => state.maxTimeout,
    getQuestionsPerTeam: state => state.questionsPerTeam,
    getDifficulty: state => state.difficulty
  },
  mutations: {
    setMaxTimeout(state, timeout) {
      state.maxTimeout = timeout
    },
    setQuestionsPerTeam(state, questionsPerTeam) {
      state.questionsPerTeam = questionsPerTeam
    },
    setDifficulty(state, difficulty) {
      state.difficulty = difficulty
    },
    setTeams(state, teams) {
      state.teams = teams
    },
    addTeam(state: any , team) {
      state.teams.push(team)
    },
    setQuestions(state, questions) {
      state.questions = questions
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setSelectedCategories(state, categories) {
      state.selectedCategories = categories
    },
    addSelectedCategory(state:any, category) {
        state.selectedCategories.push(category)
    },
    removeSelectedCategory(state:any, category) {
        state.selectedCategories = state.selectedCategories.filter((c: any) => c !== category)
    },
    setSelectedTeam(state, team) {
      state.selectedTeam = team
    },
    setUsedQuestions(state: any, question) {
      state.usedQuestions.push(question)
    },
    setTeamScore(state: any, { teamName, score }) {
      const team: any = state.teams.find((t: any) => t.name === teamName)
      if (team) {
        team.points = score
      }
    }
  },
  actions: {
    setMaxTimeout({ commit }, timeout) {
      commit('setMaxTimeout', timeout)
    },
    setQuestionsPerTeam({ commit }, questionsPerTeam) {
      commit('setQuestionsPerTeam', questionsPerTeam)
    },
    setDifficulty({ commit }, difficulty) {
      commit('setDifficulty', difficulty)
    },
    fetchTeams({ commit }) {
      const teams = localStorage.getItem('teams')
      if (teams) {
        commit('setTeams', JSON.parse(teams))
      }
    },
    addTeam({ commit, state }, team) {
      commit('addTeam', team)
      localStorage.setItem('teams', JSON.stringify(state.teams))
    },
    deleteTeam({ commit, state }, team) {
      const teams = state.teams.filter((t: any) => t.name !== team.name)
      commit('setTeams', teams)
      localStorage.setItem('teams', JSON.stringify(teams))
    },
    fetchQuestions({ commit }) {
      const getData = () => {
        return fetch('https://quest10n.tediousapp.xyz/api/json.php').then(res => {
          return res.json()
        }).then(response => {
          localStorage.setItem('data', JSON.stringify(response))
          commit('setQuestions', JSON.parse(response.questions))
          commit('setCategories', response.categories)
          commit('setSelectedCategories', response.categories)
        })
      }

      const localData: any = localStorage.getItem('data')
      if (!localData ) {
        getData()
      } else {
        fetch('https://quest10n.tediousapp.xyz/api/json.php?v').then(res => {
          return res.json();
        }).then(response => {
          const data = JSON.parse(localData)
          if(+response !== +data.v) {
            getData()
          } else {
            commit('setQuestions', JSON.parse(data.questions))
            commit('setCategories', data.categories)
            commit('setSelectedCategories', data.categories)
          }
        }).catch(err => {
          const data = JSON.parse(localData)
          commit('setQuestions', JSON.parse(data.questions))
          commit('setCategories', data.categories)
          commit('setSelectedCategories', data.categories)
        });
      }
    },
    setCategories({ commit }, categories) {
      commit('setCategories', categories)
    },
    setSelectedCategories({ commit }, categories) {
      commit('setSelectedCategories', categories)
    },
    setUsedQuestions({ commit }, question) {
        commit('setUsedQuestions', question)
    },
    changeSelectedCategory({ commit, state }, category) {
      const categories: any = state.selectedCategories
      if (categories.includes(category)) {
          commit('removeSelectedCategory', category)
      } else {
          commit('addSelectedCategory', category)
      }
    },
    setTeamScore({ commit, state }, { teamName, score }) {
      commit('setTeamScore', { teamName, score })
      localStorage.setItem('teams', JSON.stringify(state.teams))
    }
  },
  modules: {
  }
})
