import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import QuestionView from "@/views/QuestionView.vue";
import GameOverView from "@/views/GameOverView.vue";
const routes: any = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            slideDirection: 'slide-top',
        }
    },
    {
        path: '/game',
        name: 'game',
        component: QuestionView,
        meta: {
            slideDirection: 'slide-bottom',
        }
    },
    {
        path: '/game-over',
        name: 'game-over',
        component: GameOverView,
        meta: {
            slideDirection: 'slide-bottom',
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

createApp(App).use(router).use(store).mount('#app')
