<template>
 <div class="teams-container">
   <img v-if="!showSettings" class="settings-button" src="../assets/settings.svg" alt="Settings" @click="showSettings = !showSettings; showNewTeamForm = false" />
   <div class="settings-button" v-if="showSettings" @click="showSettings = !showSettings; showNewTeamForm = false">Close</div>
   <div class="settings" v-if="showSettings">
     <div class="settings-header">
       <div :class="{'settings-header__item': true, 'selected': showCategories}" @click="showCategories = !showCategories">
          <img src="../assets/category.svg" />
       </div>
       <div :class="{'settings-header__item fix-time-btn': true, 'selected': showTime}" @click="showTime = !showTime">
          <img src="../assets/time.svg" />
       </div>
       <div :class="{'settings-header__item': true, 'selected': showQuestions}" @click="showQuestions = !showQuestions">
         <img src="../assets/rounds.svg" />
       </div>
       <div :class="{'settings-header__item': true, 'selected': showDifficulty}" @click="showDifficulty = !showDifficulty">
         <img src="../assets/difficulty.svg" />
       </div>
     </div>
     <div class="settings-content">
       <div class="settings-content__item categories" v-if="showCategories">
         <div v-for="(category, i) in getCategories" :key="i" :class="{'selected': isCategorySelected(category)}" @click="changeSelectedCategory(category)">
           {{ category }}
         </div>
       </div>
       <div  v-if="showTime">
        <div class="settings-content__description">How many seconds each round will last</div>
         <div class="settings-content__item time">
            <div v-for="(time, i) in [30, 40, 50, 60, 90]" :key="i" :class="{'time-item': true, 'selected': time === selectedTime}" @click="changeSelectedTime(time)">
              {{ time }}
            </div>
         </div>
        </div>
       <div v-if="showQuestions">
         <div class="settings-content__description">How many rounds per game</div>
         <div class="settings-content__item questions-per-game">
            <div v-for="(questions, i) in [5, 10, 15, 20]" :key="i" :class="{'question-item': true, 'selected': questions === selectedQuestions}" @click="changeSelectedQuestions(questions)">
              {{ questions }}
            </div>
         </div>
       </div>
       <div v-if="showDifficulty">
         <div class="settings-content__description">Select how difficult the questions should be</div>
         <div class="settings-content__item difficulty">
            <div v-for="(difficulty, i) in ['All', 'Easy', 'Medium', 'Hard']" :key="i" :class="{'question-item': true, 'selected': difficulty === selectedDifficulty}" @click="changeSelectedDifficulty(difficulty)">
              {{ difficulty }}
            </div>
         </div>
       </div>
     </div>
   </div>
   <div class="actions-container" v-if="!showSettings">
     <button class="button" @click="showNewTeamForm = !showNewTeamForm; showSettings = false ">
       Create a New Team
     </button>
   </div>
   <div class="team-form" v-if="showNewTeamForm && !showSettings">
      <input type="text" v-model="newTeam.name" placeholder="Team Name" />
      <div class="color-picker">
        <div :class="['color', newTeam.color === color ? 'selected' : '']" v-for="color in colors" :key="color" :style="{ backgroundColor: color }" @click="newTeam.color = color"></div>
      </div>
      <button class="button submit" @click="createTeam">
        Create
      </button>
    </div>
   <ul class="teams-content" v-if="!showSettings">
      <li v-for="team in getTeams" :key="team.id" :style="`background-color: ${team.color}33; background-image: linear-gradient(145deg, ${team.color}33 6%, #373f62 25%); border: 0.1px solid ${team.color}`">
        <span>{{ team.name }} <span class="points-label">( {{ team.points }} points )</span></span>
        <span @click="deleteTeam(team)"><img src="../assets/bin.svg" alt="Delete team" /></span>
      </li>
    </ul>
   <div v-if="!showSettings">  
     <div class="start-game" v-if="readyToPlay">
       <router-link to="/game">
          <button class="button submit">
            Let's Play!
          </button>
       </router-link>
     </div>
     <div class="text-label" v-else>
       Create at least two teams to play
     </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HomeView',
  data() {
    return {
      showNewTeamForm: false,
      showSettings: false,
      selectedTime: 0,
      selectedQuestions: 0,
      selectedDifficulty: '',
      showCategories: true,
      showTime: false,
      showQuestions: false,
      showDifficulty: false,
      newTeam: {
        name: '',
        color: '',
        points: 0
      },
      colors: [
        '#f39200',
        '#1d72b8',
        '#6aa84f',
        '#e30613',
        '#831f82'
      ]
    }
  },
  computed: {
    ...mapGetters(['getTeams', 'getTeams', 'getCategories', 'getSelectedCategories', 'getMaxTimeout', 'getQuestionsPerTeam', 'getDifficulty']),
    readyToPlay() {
      return this.getTeams.length > 1;
    },
    isCategorySelected() {
      return category => this.getSelectedCategories.includes(category);
    }
  },
  methods: {
    changeSelectedDifficulty(difficulty) {
      this.selectedDifficulty = difficulty;
      this.$store.dispatch('setDifficulty', difficulty);
    },
    changeSelectedQuestions(questions) {
      this.selectedQuestions = questions;
      this.$store.dispatch('setQuestionsPerTeam', questions);
    },
    changeSelectedTime(time) {
      this.selectedTime = time;
      this.$store.dispatch('setMaxTimeout', time);
    },
    createTeam() {
      if (!this.newTeam.name) {
        const adjectives = ["Flying", "Mighty", "Incredible", "Furious", "Brave", "Majestic", "Swift", "Red", "Blue", "Green"];
        const nouns = ["Dragons", "Wizards", "Knights", "Titans", "Warriors", "Eagles", "Lions", "Sharks", "Thunder", "Vipers"];

        const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

        this.newTeam.name = `${randomAdjective} ${randomNoun}`;
      }
      if (!this.newTeam.name || this.getTeams.find(team => team.name === this.newTeam.name)) {
        alert('This name already exists, please choose another one');
        return;
      }
      this.$store.dispatch('addTeam', this.newTeam);
      this.showNewTeamForm = false;
      this.newTeam = {
        name: '',
        color: '',
        points: 0
      }
    },
    deleteTeam(team) {
      this.$store.dispatch('deleteTeam', team);
    },
    changeSelectedCategory(category) {
      if (this.getSelectedCategories.length <= 1 && this.getSelectedCategories.includes(category)) {
        alert('You must select at least 1 category');
        return;
      }
      this.$store.dispatch('changeSelectedCategory', category);
    }
  },
  watch: {
    showCategories (val) {
      if (val) {
        this.showTime = false;
        this.showQuestions = false;
        this.showDifficulty = false;
      }
    },
    showTime (val) {
      if (val) {
        this.showCategories = false;
        this.showQuestions = false;
        this.showDifficulty = false;
      }
    },
    showQuestions (val) {
      if (val) {
        this.showCategories = false;
        this.showTime = false;
        this.showDifficulty = false;
      }
    },
    showDifficulty (val) {
      if (val) {
        this.showCategories = false;
        this.showTime = false;
        this.showQuestions = false;
      }
    }
  },
  mounted() {
    this.selectedTime = this.getMaxTimeout
    this.selectedQuestions = this.getQuestionsPerTeam
    this.selectedDifficulty = this.getDifficulty
  }
}
</script>

<style scoped lang="scss">
.teams-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .actions-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .team-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button.button,
    input {
      margin: 10px!important;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      text-align: center;
    }
    .color-picker {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .color {
        width: 30px;
        height: 30px;
        border-radius: var(--border-radius);
        margin: 5px;
        cursor: pointer;
        &.selected {
          border: 2px solid var(--color-white);
        }
      }
    }
  }
  .categories {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    div {
      border: 1px solid #515151;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background: #b6b6b6;
      color: #434343;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        font-size: 20px;
        color: #038103;
        font-weight: bold;
        background: #d2d2d2;
        height: 26px;
        width: 26px;
        border-radius: 8px;
        box-shadow: 0 1px 2px #0000007d;
      }
      &.selected {
        background: var(--color-white);
        color: black;
        box-shadow: 0 2px 4px #515151;
        &:before {
          content: "✓";
          background: white;
        }
      }
    }
  }
  .settings-button {
    position: absolute;
    top: -40px;
    right: 0;
    height: 30px;
  }
}
.settings {
  width: 100%;
  margin-top: 30px;
  .settings-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    &__item {
      &.fix-time-btn {
        img {
          width: 26px;
          top: 3px;
          position: relative;
        }
      }
      background: white;
      padding: 13px 10px 10px 10px;
      margin: 0;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      box-sizing: border-box;
      img {
        width: 30px;
      }
      &.selected {
        border: 1px solid #000000;
      }
    }
  }
  .settings-content {
    &__item:not(.categories) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 0;
      padding: 20px 0;
      .time-item,
      .question-item {
        background: #ececec;
        border-radius: 50%;
        color: black;
        box-shadow: 0 1px 4px #00000087;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &.selected {
          background: #ffffff00;
          color: white;
          border: 1px solid white;
        }
      }
      &.difficulty {
        .question-item {
          width: 70px;
          border-radius: 8px;
          text-align: center;
        }
      }
    }
    &__description {
      margin: 30px 0 10px 0;
    }
  }
  .text-label {
    text-align: center;
    color: #dadada;
    font-style: italic;
  }
}

</style>
