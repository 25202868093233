<template>
  <router-view v-slot="{ Component }" class="router-view">
    <transition name="slide-top" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <div class="body-background" />
</template>
<script>
import { useStore } from 'vuex';
import { ref, onMounted, onBeforeUnmount } from 'vue';
export default {
  setup() {
    const store = useStore();
    const wakeLock = ref(null);
    
    store.dispatch('fetchQuestions');
    store.dispatch('fetchTeams');
    
    const wakeScreenOn = async () => {
      if (!wakeLock.value) {
        wakeLock.value = await navigator.wakeLock.request('screen');
      }
    };

    const wakeScreenOff = async () => {
      if (wakeLock.value) {
        await wakeLock.value.release();
        wakeLock.value = null;
      }
    };

    onMounted(() => {
      wakeScreenOn();
    });
    
    onBeforeUnmount(() => {
      wakeScreenOff();
    });
    return {}
  }
};
</script>
<style lang="scss">
:root {
  --color-primary: #68a1aa;
  --color-secondary: #313f65;
  --color-tertiary: #d78d71;
  --color-quaternary: #d1709d;
  --color-quinary: #e9c2e7;
  --color-senary: #a090cc;
  --color-button-right: #2a5d68;
  --color-button-left: #7dbbb9;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #ccc;
  --color-gray-light: #eee;
  --color-gray-dark: #aaa;
  --color-gray-darker: #999;
  --color-gray-darkest: #666;
  --text-color: var(--color-white);
  --border-radius: 8px;
  --timer-width: 0%;
  --timer-color: #61c666;
}
html, body {
  margin: 0;
  padding: 0;
  position: relative;
}
.router-view {
  position: relative;
  z-index: 2;
  margin: 0 20px;
}
.body-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-image: linear-gradient(45deg, var(--color-secondary) 55%, var(--color-primary) 100%);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  max-width: 768px;
  margin: 0 auto;
  .button {
    background-color: var(--color-white);
    border: none;
    border-radius: var(--border-radius);
    color: var(--color-gray-darkest);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin: 20px 0 0;
    position:relative;
    &:before, &:after {
      content:"";
      position:absolute;
      z-index:-1;
      -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
      -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
      box-shadow:0 0 20px rgba(0,0,0,0.8);
      top:50%;
      bottom:0;
      left:10px;
      right:10px;
      -moz-border-radius:100px / 10px;
      border-radius:100px / 10px;
    }
    &:after {
      right:10px;
      left:auto;
      -webkit-transform:skew(8deg) rotate(3deg);
      -moz-transform:skew(8deg) rotate(3deg);
      -ms-transform:skew(8deg) rotate(3deg);
      -o-transform:skew(8deg) rotate(3deg);
      transform:skew(8deg) rotate(3deg);
    }
    &:hover {
      background-color: var(--color-white);
    }
    &.submit {
      background-image: linear-gradient(45deg, #ffffff 20%, #d4d4d4 100%);
    }
  }
  ul.teams-content {
    margin-top: 40px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    li {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      color: var(--color-white);
      font-weight: bold;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      .points-label {
        color: #b8b8b8;
        font-weight: 100;
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  .hidden {
    display: none;
  }
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
  transition: transform 0.3s;
}

.slide-right-enter,
.slide-right-leave-to,
.slide-left-enter,
.slide-left-leave-to{
  transform: translateX(120%);
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: transform 0.3s;
}

.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translateY(120%);
}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: transform 0.3s;
}

.slide-top-enter,
.slide-top-leave-to {
  transform: translateY(-120%);
}
</style>
